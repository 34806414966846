import React from 'react'
import './styles.css';

function Reviews() {
  return (
    <div className="reviews-cards-wrap" id="reviews">
      <h1>Customer Reviews</h1>
        <div className="card-first-grp">
          <div className="card-container-rev">
            <p>
              "I cannot express how impressed I was with R.D. Mechanical my wife and  I are. 
              When our furnace broke down repeatedly over the course of two or three weeks, every time we called we were made to feel like a priority and felt very well cared for and in good hands. 
              Even though it required repeat visits for a few system bugs, RD Mechanical kept coming back until the problems were solved.  I will be a repeat customer in the future and would highly recommend RD Mechanical to anyone looking for reliable, conscientious and friendly staff." 
              <br></br>
              <b>-Elliot Tweedy</b>
            </p>
          </div>
          <div className="card-container-rev">
            <p>
              "After inquiring with many other companies and individuals and no one being able to provide us with a product we liked; Rob was able to bring our vision of an updated kitchen table to life. He took our 35 year old IKEA table and turned into a contemporary statement piece of furniture that friends and family envy. Rob went above and beyond to ensure the final product was exactly what we were wanting. Rob went above and beyond to ensure the final product was exactly what we were wanting. We would recommend him to anyone and everyone."
              <br></br>
              <b>-Shawn and Sabra Roberts</b>
            </p>
          </div>
        </div>
        <div className="card-second-grp">
          <div className="card-container1">
            <p>
              "Well our AC went down on the long weekend… We called RD on the holiday Monday and they came out to look during a holiday in NC wow ! No other company I've dealt with would do this! He looked it over and the bad news was it was toast. They gave a quote for a new system and we double checked his quote which was very reasonable so we gave the ok to move forward with the installation. Well things did not go smoothly for them and took a bit longer than he'd planned a couple problems that I'm sure with any other company I would have paid extra for but not RD true to his quote no extra charges wow I was sure we get charged for the extras So timely, fair. Clean and true to his word. You don't see this very often anymore.If you need and work done I highly recommend RD you will not be disappointed!
              Thanks Rob"
              <br></br>
              <b>-Gord Larson</b>
            </p>
          </div>
        </div>
    </div>
  )
}

export default Reviews;