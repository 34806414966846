import { Navbar, Nav, Container } from "react-bootstrap";
import './styles.css'

function NavigationBar() {
  return (
    <div>
      <Navbar varient="dark" expand="lg" fixed="top" className="navbar" className='m-auto'>
        <Container fluid>
          <Navbar.Brand href="#"></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link href="#hero-wrap">Home</Nav.Link>
              <Nav.Link href="#whatweoffer">What We Offer</Nav.Link>
              <Nav.Link href="#whoweare">Who Are We?</Nav.Link>
              <Nav.Link href="#employees">Our Employees</Nav.Link>
              <Nav.Link href="#reviews">Reviews</Nav.Link>
              <Nav.Link href="#contactus">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavigationBar;
