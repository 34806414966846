import "./styles.css";

function Ourfamily() {
    return(
<div className="family">
    <div>
        <img src="photos/family.jpg" />
    </div>
    <div className="family-text-wrap">
        <div>

        <img src="photos/rd-logo.png" />
        <h1>From our family to yours!</h1>
        </div>
    </div>
</div>
    );
}

export default Ourfamily;