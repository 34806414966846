import {
  BsTelephoneFill,
  BsEnvelopeFill,
  BsCalendarCheckFill,
} from "react-icons/bs";
import "./styles.css";

function Footer() {
  return (
    <div className="footer">
      <img src="photos/rd-logo.png" className="footer-logo" />
      <div>
        <div className="footer-content">
            <BsTelephoneFill size={30} />
          <a href="tel:403-330-2718" className="footer-click">
            <p className="footer-title">Call us at:
            403-330-2718</p>
          </a>
        </div>
        <div className="footer-content">
            <BsEnvelopeFill size={30} />
          <a href="mailto:Rob@rdmechanical.ca" className="footer-click">
            <p className="footer-title">Email us at:
            Rob@rdmechanical.ca</p>
          </a>
        </div>

        <div className="footer-content">
          <BsCalendarCheckFill size={30} />
          <p>Availability by Appointment <br/>24 Hour Emergency Service</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
