import "./styles.css";

function Employee() {
  return (
    <div className="employee" id="employees">
      <h1>Meet Our Featured Employees</h1>
          <div className="employee-wrap-1">
            <div className="employee-card">
              <img src="photos/rob.jpg" className="employee-img" />
                <div className="employee-content">
                  <h2>Robert Drouin</h2>
                  <p>Owner/Lead Contractor/ Lead Operator</p>
                </div>
            </div>
            <p>"I’ve been in the trades for nearly twenty years. I have multiple red seal trade certifications with nearly two decades of experience in industrial, commercial, and residential installations. 
              It’s my goal to bring all my skills, and more, to make sure you have the best, most seamless experience possible!"</p>
          </div>
          <div className="employee-wrap-1">
            <div className="employee-card">
              <img src="photos/kai.jpg" className="employee-img" />
                <div className="employee-content">
                  <h2>Kai Luedtke</h2>
                  <p>Red Seal HVAC Journeyman</p>
                </div>
            </div>
            <p>"I'm a Red Seal sheet metal worker who graduated from Brooks Composite High School in 2011. I later went on to SAIT, where I graduated in 2017. My goal here is to
              give you 110%, and make sure you are exceptionally satisfied with the work I've done!"</p>
          </div>
    </div>
  );
}

export default Employee;
