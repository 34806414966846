import NavigationBar from "./components/NavigationBar/Navigationbar";
import Whoweare from "./components/WhoWeAre/Whoweare";
import Whatweoffer from "./components/WhatWeOffer/Whatweoffer";
import Hero from "./components/Hero/Hero";
import './App.css'
import Employee from "./components/Employee/Employee";
import Reviews from "./components/Reviews/reviews";
import Contact from "./components/ContactUs/Contact"
import Footer from "./components/Footer/Footer";
import Ourfamily from "./components/OurFamily/Ourfamily";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <main>
        <div>
          <NavigationBar />
          <Hero />
          <Whatweoffer />
          <Whoweare />
          <Employee />
          <Reviews />
          <Contact />
          <Ourfamily />
          <Footer /> 
        </div>
      </main>
    </div>
  );
}

export default App;
