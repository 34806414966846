import "./styles.css";
import { BsTelephoneFill, BsEnvelopeFill, BsGeoAltFill, BsFacebook, BsInstagram, } from "react-icons/bs";

function Contact() {
  return (
    <div className="contact" id="contactus" >
          <div className="contact-title">
          <h1>Contact Us Today For</h1>
          <h1>Any Information or a Quote!</h1>
          </div>
      <div className="contact-wrap">
        <div className="mains">


          <div>
            <a href="tel:403-330-2718" className="contact-click">
            <BsTelephoneFill size={50} />
            <p className="contact-title">Call us at:</p>
            <p>403-330-2718</p>
            </a>
          </div>
          <div>
            <a href="mailto:Rob@rdmechanical.ca" className="contact-click">
            <BsEnvelopeFill size={50} />
            <p className="contact-title">Email us at:</p>
            <p>Rob@rdmechanical.ca</p>
            </a>
          </div>
        </div>
        <div className="socials">

          <div>
            <a href="https://www.facebook.com/rdmechanicalcontracting" target="_blank" className="contact-click">
            <BsFacebook size={50} />
            <p className="contact-title">Follow us on </p>
            <p>Facebook</p>
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/rdmechanicalcontracting1/?hl=en" target="_blank" className="contact-click">
            <BsInstagram size={50} />
            <p className="contact-title">Follow us on </p>
            <p>Instagram</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
