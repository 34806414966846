import Card from "react-bootstrap/Card";
import "./styles.css";

function Whoweare() {
  return (
    <div className="text" id="whoweare">
      <div className="whowe-content">

          <h2>RD Mechanical</h2>
          <p>
            RD Mechanical Contracting was created in 2016 out of the desire to
            offer responsible solutions relative to comfort, reliability, and
            customer satisfaction to southern Albertans. The resulting years of
            success have proven both challenging and rewarding in there own
            right; giving us strong roots in our client base, supplier
            structure, and many friends have been made out of our clients along
            the way. We pride ourselves on doing whats right - thats where the
            power of knowledge and the benefit of experience truly comes into
            play.
          </p>
      </div>
          <img src="photos/rd-logo.png" />
    </div>
  );
}

export default Whoweare;
