import "./styles.css";

function Hero() {
  return (
<div className="hero-wrap" id="hero-wrap">

  <div className="hero-image">
    <div className="hero-text">
      <img src="photos/rd-logo-white.png" className="hero-text-title"/>
      <h2 className="hero-text-subtitle">Plumbing, Heating and Air Conditioning Services You Can Trust</h2>
      <p className="hero-text-para">We are a fully insured and licensed company, providing 24 hour emergency service to our valued customers. 
        Our Certified Journeyman Technicians do it right the first time, and all work comes with a minimum 1 year craftsmanship warranty.</p>
    </div>

  </div> 
</div >

  );
}

export default Hero;
