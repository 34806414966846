import {
  BsHouse,
  BsBuilding,
  BsThermometerSun,
  BsThermometerSnow,
  BsWrench,
  BsBucket,
} from "react-icons/bs";
import "./styles.css";

function Whatweoffer() {
  return (
    <div className="offer-cards-wrap" id="whatweoffer">
      <div className="card-first-grp">
        <div className="card-container-1">
          <BsHouse className="card-res-img" size={100} />
          <h2>Residential</h2>
          <p>
            We will service all your contracting needs, from heating, to air
            conditioning, to plumbing. Whatever needs you have, we will be able
            to solve it.
          </p>
        </div>
        <div className="card-container-1">
          <BsBuilding className="card-res-img" size={100} />
          <h2>Commercial</h2>
          <p>
            All of our residential services are also offered on a commercial
            scale. Whatever your companies needs, we will be able to service
            them.
          </p>
        </div>
      </div>
      <div className="card-second-grp">
        <div className="card-container-2">
          <BsWrench className="card-res-img-k" size={100} />
          <h2>Plumbing</h2>
          <p>
            We offer pipe repairs, faucet repairs and replacements, water filtration maintenance, and more!
          </p>
        </div>
        <div className="card-container-2">
          <BsThermometerSun className="card-res-img-t" size={100} />
          <h2>Heating</h2>
          <p>
            We offer Boilers, Furnaces, In-Floor Radiant, and Perimeter Heating
            service, installations, and repairs.
          </p>
        </div>
        <div className="card-container-2">
          <BsThermometerSnow className="card-res-img-t" size={100} />
          <h2>A/C</h2>
          <p>
            We offer Service, installation, and repair of Central Air Systems,
            Portable Units and Packaged Units.
          </p>
        </div>
        <div className="card-container-2">
          <BsBucket className="card-res-img-k" size={100} />
          <h2>Duct Cleaning</h2>
          <p>
            We offer duct cleaning services at an affordable price to fit your needs.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Whatweoffer;
